/* Plone form style overrides */

.documentDescription {
    @extend .text-secondary;
    @extend .small;
}
.formQuestion {
    @extend .font-weight-bold;
    margin-bottom: 0;
}
.formHelp {
    @extend .form-text;
    @extend .text-secondary;
    @extend .d-block;
    @extend .small;
}
.formControls {
    input[name*='form.button'] {
        @extend .btn;
        @extend .btn-sm;
        @extend .btn-secondary;
        &[name='form.button.save'],
        &[name='form.buttons.save'] {
            @extend .btn-primary;
        }
    }
}

ul.configlets>li>form,
form.rowlike,
form.loginform,
form.senaite-form,
form[name='analysisrequest_add_form'],
form[action*='-controlpanel'],
form[name='header_form'],
form[name='listing_form'],
form[name='edit_form'] {
    input, select {
        width: auto!important;
    }
    select {
        @extend .form-control;
        @extend .form-control-sm;
    }
    .fieldRequired {
        &:after {
            color: $red;
            content: "·";
        }
    }
    span.required {
        &:before {
            color: $red;
            content: "·";
            padding-right: 0.25em;
        }
    }
    .fieldErrorBox {
        @extend .text-danger;
        @extend .small;
    }
    .field.error input,
    .field.error select {
        @extend .is-invalid;
    }
    .field {
        @extend .form-group;
    }
    span.option,
    .field.ArchetypesBooleanWidget,
    .field.RejectionSetupWidget {
        @extend .form-check;
        input[type='checkbox'] {
            @extend .form-check-input;
        }
    }
    input[type='file'] {
    }
    input[type='text'] {
        @extend .form-control;
        @extend .form-control-sm;
    }
    input[type='checkbox'] {
    }
    input[type='button'] {
        @extend .btn;
        @extend .btn-sm;
        @extend .btn-outline-secondary;
    }
    textarea {
        @extend .form-control;
    }
}

/* Sample's header form */
.portaltype-analysisrequest form[name='header_form'] {
  td.label {
    background-color: #f8f9fa;
  }
  .field {
    margin-bottom: 0;
  }
}
